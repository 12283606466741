export class Tile {
    x: number;
    y: number;
    type: number;
    removed: boolean;
    shift: number;
    velocity: number;
    alpha: number;
    processed: boolean;

    constructor(x: number, y: number, type: number, shift: number) {
        this.x = x;
        this.y = y;
        this.type = type;
        this.removed = false;
        this.shift = shift;
        this.velocity = 0;
        this.alpha = 1;
        this.processed = false;
    }
}
