export class Bubble {
    x: number;
    y: number;
    angle: number;
    speed: number;
    dropspeed: number;
    tiletype: number;
    visible: boolean;

    constructor() {
        this.x = 0;
        this.y = 0;
        this.angle = 0;
        this.speed = 1000;
        this.dropspeed = 900;
        this.tiletype = 0;
        this.visible = false;
    }
}

export class NextBubble {
    x: number;
    y: number;
    tiletype: number;

    constructor() {
        this.x = 0;
        this.y = 0;
        this.tiletype = 0;
    }
}

export class Player {
    get bubble(): Bubble {
        return this._bubble;
    }

    set bubble(value: Bubble) {
        this._bubble = value;
    }
    x: number;
    y: number;
    angle: number;
    tiletype: number;
    nextbubble: NextBubble;

    private bubble: Bubble;

    constructor() {
        this.x = 0;
        this.y = 0;
        this.angle = 0;
        this.tiletype = 0;
        this.bubble = new Bubble();
        this.nextbubble = new NextBubble();
    }
}
