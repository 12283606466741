import {list} from "postcss";

export interface GameConfigInterface {
    turnsBeforeDrop?: number;
    secondsBeforeDrop?: number;
    speedOfShot: number,
    ballSprite: string, // The path to the sprint of the bals
    numberOfBallsOnSprite: number, // The amount of balls on the sprite.
    ballsPerRow: number,
    bottomBarBackgroundColor: string,
    ballSettings?: ballSettings[],
    fontSize?: string,
    fontColor?: string,
    font?: string,
};

export interface ballSettings {
    chance: number;
    points: number;
}

export class GameConfig implements GameConfigInterface {
    turnsBeforeDrop = 0; // The amount of turns a player can de before the balls drop 1 row.
    secondsBeforeDrop = 0; // How many seconds before the balls drop
    speedOfShot = 1000; // The speed you shoot your own ball
    ballsPerRow = 8;
    ballSprite = '/images/bubble-sprites.png'; // The path to the sprite of the balls
    numberOfBallsOnSprite = 6; // The amount of balls on the sprite.
    ballSettings = [
        {chance: 1, points: 100}, // Default
        {chance: 1, points: 500},
    ]
    bottomBarBackgroundColor = '#000000';

    fontSize = '12px';
    fontColor = 'black';
    font = 'Arial';
}

// turnsBeforeDrop=0
// secondsBeforeDrop=30
// speedOfShot=1000
// ballsPerRow=8
// ballSprite=/images/bubble-sprites.png
// ballSettings=1:100,1:500
// numberOfBallsOnSprite=6
// bottomBarBackgroundColor=#000000;
// fontSize=12px
// fontColor=black
// font=Arial
