import { BubbleShooter } from "./Game";
import {GameConfigInterface, GameConfig} from "./GameConfig";

export class Configurator {
    async init(config: GameConfig) {

    this.preloadImages([
        config.ballSprite, // this doesn't work
    ]).then(() => {
      new BubbleShooter(config);
    });
  }

  async preloadImages(sources: string[]) {
    return new Promise<void>((resolve) => {
      let loadedCounter = 0;
      sources.forEach((src) => {
          if(!src) {
              resolve();
              return;
          }
        const image = new Image();
        image.onload = () => {
          loadedCounter += 1;
          if (loadedCounter === sources.length) {
            resolve();
          }
        };
        image.onerror = () => {
          console.error(src + " was not found");
          loadedCounter += 1;
          if (loadedCounter === sources.length) {
            resolve();
          }
        };
        image.src = src;
      });
    });
  }
}
