import {Game, Platform} from "./Platform";
import {ballSettings, GameConfigInterface} from "./GameConfig";
import {Player} from "./Player";
import {Tile} from "./Tile";
import {load} from "webfontloader";
import {text} from "node:stream/consumers";

export class BubbleShooter implements Game{
    platform: Platform;
    config: GameConfigInterface;

    windowSize = {
        width: window.innerWidth,
        height: window.innerHeight,
    }

    canvas: HTMLCanvasElement;
    context: CanvasRenderingContext2D;

    // Timing and frames per second
    lastframe = 0;
    fpstime = 0;
    framecount = 0;
    fps = 0;

    initialized = false;

    resizeTimeout: number | null = null;

    level = {
        columns: 6,    // Number of tile columns EDITABLE

        rows: 20,       // Number of tile rows, starting amount
        x: 4,           // X position
        y: 4,          // Y position
        width: 0,       // Width, gets calculated
        height: 0,      // Height, gets calculated
        tilewidth: 0,  // Visual width of a tile
        tileheight: 0, // Visual height of a tile
        rowheight: 50,  // Height of a row
        radius: 20,     // Bubble collision radius
        tiles: []       // The two-dimensional tile array
    };

    neighborsoffsets = [[[1, 0], [0, 1], [-1, 1], [-1, 0], [-1, -1], [0, -1]], // Even row tiles
        [[1, 0], [1, 1], [0, 1], [-1, 0], [0, -1], [1, -1]]];  // Odd row tiles

    bubblecolors = 3;

    gamestates = { init: 0, ready: 1, shootbubble: 2, removecluster: 3, gameover: 4 };
    gamestate = 0;

    score = 0;

    turncounter = 0;
    rowoffset = 0;

    // Animation variables
    animationstate = 0;
    animationtime = 0;

    // Clusters
    showcluster = false;
    cluster = [];
    floatingclusters = [];

    // Images
    images = [];
    bubbleimage;

    // Image loading global variables
    loadcount = 0;
    loadtotal = 0;
    preloaded = false;
    existingcolors: number[] = [];

    player: Player;

    private timedDropInterval: any = null;

    constructor(config: object) {
        this.platform = new Platform();
        this.config = <GameConfigInterface>config;

        this.canvas = window.document.getElementById("game-screen") as HTMLCanvasElement;
        this.context = this.canvas.getContext("2d")!;
        this.resizeCanvas();

        window.addEventListener('resize', this.handleResize);

        this.init();
    }

    private handleResize = () => {
        if (this.resizeTimeout) {
            clearTimeout(this.resizeTimeout);
        }
        this.resizeTimeout = window.setTimeout(() => {
            this.resizeCanvas();
            this.adjustGameElements();
        }, 250);
    }

    private resizeCanvas() {
        this.windowSize.width = window.innerWidth;
        this.windowSize.height = window.innerHeight;
        this.canvas.width = this.windowSize.width;
        this.canvas.height = this.windowSize.height;
    }

    private adjustGameElements() {
        // Adjust game elements based on new canvas size
        // This method should be implemented to resize and reposition game elements
        // For example:
        // this.level.width = this.windowSize.width;
        // this.level.height = this.windowSize.height;
        // Recalculate positions of bubbles, player, etc.
    }

    init() {
        window.document.body.style.fontSize = this.config.fontSize + "px";
        window.document.body.style.color = <string>this.config.fontColor;
        window.document.body.style.fontFamily = <string>this.config.font;

        console.log('Init game...');

        this.bubblecolors = this.config.numberOfBallsOnSprite;
        this.level.columns = this.config.ballsPerRow;

        this.platform.init(this, this.config);
        this.platform.preloadSounds(this.config);

        this.player = new Player();
        this.player.bubble.speed = this.config.speedOfShot;

        // Load images
        this.images = this.loadImages([
            this.config.ballSprite
        ]);
        this.bubbleimage = this.images[0];

        // Add mouse events
        this.canvas.addEventListener("mousemove", this.onMouseMove);
        this.canvas.addEventListener("mousedown", this.onMouseDown);

        var widthPerTiles = (this.windowSize.width - (this.level.x*2)) / this.level.columns;
        this.level.tilewidth = (this.windowSize.width - (this.level.x*2) - (widthPerTiles/2)) / this.level.columns;
        this.level.tileheight = this.level.tilewidth;
        this.level.rowheight = this.level.tilewidth;

        this.level.radius = this.level.rowheight/2;

        this.level.width = this.windowSize.width;
        this.level.height = this.windowSize.height - this.level.tileheight - 22;

        this.level.rows = Math.round(this.level.height / this.level.tileheight);

        // Initialize the two-dimensional tile array
        for (var i=0; i<this.level.columns; i++) {
            this.level.tiles[i] = [];
            for (var j=0; j<this.level.rows; j++) {
                // Define a tile type and a shift parameter for animation
                this.level.tiles[i][j] = new Tile(i, j, 0, 0);
            }
        }

        // Init the player
        this.player.x = this.level.x + this.level.width/2 - this.level.tilewidth/2;
        this.player.y = this.level.y + this.level.height;
        this.player.angle = 90;
        this.player.tiletype = 0;

        this.player.nextbubble.x = this.player.x - 2 * this.level.tilewidth;
        this.player.nextbubble.y = this.player.y;

        // this.newGame();

        // Enter main loop
        this.main(0);
    }

    private main = (tframe: number) => {
        // Request animation frames
        window.requestAnimationFrame(this.main);

        this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);

        this.update(tframe);
        this.render();
    }

    private checkForTimedDrop() {
        if (!this.config.secondsBeforeDrop) {
            return;
        }

        // Clear existing interval if there is one
        if (this.timedDropInterval !== null) {
            return;
        }

        this.timedDropInterval = setInterval(() => {
            console.log('Timed drop...');

            if (this.gamestate === this.gamestates.ready) {
                this.addBubbles();
                this.rowoffset = (this.rowoffset + 1) % 2;
                if (this.checkGameOver()) {
                    clearInterval(this.timedDropInterval);
                    this.timedDropInterval = null;
                }
            }
        }, this.config.secondsBeforeDrop * 1000);
    }

    private update(tframe) {
        var dt = (tframe - this.lastframe) / 1000;
        this.lastframe = tframe;

        // Update the fps counter
        this.updateFps(dt);

        if (this.gamestate == this.gamestates.ready) {
            // Game is ready for player input
        } else if (this.gamestate == this.gamestates.shootbubble) {
            // Bubble is moving
            this.stateShootBubble(dt);
        } else if (this.gamestate == this.gamestates.removecluster) {
            // Remove cluster and drop tiles
            this.stateRemoveCluster(dt);
        }
    }

    private setGameState(newgamestate) {
        console.log('New gamestate: ' + newgamestate);
        this.gamestate = newgamestate;

        this.animationstate = 0;
        this.animationtime = 0;

        switch (this.gamestate) {
            case this.gamestates.ready:
                console.log('Sending game ready event.');
                this.platform.gamestarted();
                return;
            case this.gamestates.gameover:
                console.log('Sending gameover event.');
                this.platform.gameover(this.score, null, null);
                (document.getElementById('game-screen') as HTMLElement).style.opacity = '0';

                return;
        }
    }

    private stateShootBubble(dt) {
        // Bubble is moving

        // Move the bubble in the direction of the mouse
        this.player.bubble.x += dt * this.player.bubble.speed * Math.cos(this.degToRad(this.player.bubble.angle));
       this. player.bubble.y += dt * this.player.bubble.speed * -1*Math.sin(this.degToRad(this.player.bubble.angle));

        // Handle left and right collisions with the level
        if (this.player.bubble.x <= this.level.x) {
            // Left edge
            this.player.bubble.angle = 180 - this.player.bubble.angle;
            this.player.bubble.x = this.level.x;
        } else if (this.player.bubble.x + this.level.tilewidth >= this.level.x + this.level.width) {
            // Right edge
            this.player.bubble.angle = 180 - this.player.bubble.angle;
            this.player.bubble.x = this.level.x + this.level.width - this.level.tilewidth;
        }

        // Collisions with the top of the level
        if (this.player.bubble.y <= this.level.y) {
            // Top collision
            this.player.bubble.y = this.level.y;
            this.snapBubble();
            return;
        }

        // Collisions with other tiles
        for (var i=0; i<this.level.columns; i++) {
            for (var j=0; j<this.level.rows; j++) {
                var tile = this.level.tiles[i][j];

                // Skip empty tiles
                if (tile.type < 0) {
                    continue;
                }

                // Check for intersections
                var coord = this.getTileCoordinate(i, j);
                if (this.circleIntersection(this.player.bubble.x + this.level.tilewidth/2,
                    this.player.bubble.y + this.level.tileheight/2,
                    this.level.radius,
                    coord.tilex + this.level.tilewidth/2,
                    coord.tiley + this.level.tileheight/2,
                    this.level.radius)) {

                    // Intersection with a level bubble
                    this.snapBubble();
                    return;
                }
            }
        }
    }

    private stateRemoveCluster(dt) {
        let oldScore = this.score;

        if (this.animationstate == 0) {
            this.resetRemoved();

            // Mark the tiles as removed
            for (var i=0; i<this.cluster.length; i++) {
                // Set the removed flag
                this.cluster[i].removed = true;
            }

            this.cluster.forEach((tile) => {
                this.score += this.findScoreForTile(tile);
            })

            // Find floating clusters
            this.floatingclusters = this.findFloatingClusters();

            if (this.floatingclusters.length > 0) {
                // Setup drop animation
                for (var i=0; i<this.floatingclusters.length; i++) {
                    for (var j=0; j<this.floatingclusters[i].length; j++) {
                        var tile = this.floatingclusters[i][j];
                        tile.shift = 0;
                        tile.shift = 1;
                        tile.velocity = this.player.bubble.dropspeed;
                        console.log(tile);

                        this.score += this.findScoreForTile(tile);
                    }
                }
            }

            this.animationstate = 1;
        }

        if (this.animationstate == 1) {
            // Pop bubbles
            var tilesleft = false;
            for (var i=0; i<this.cluster.length; i++) {
                var tile = this.cluster[i];

                if (tile.type >= 0) {
                    tilesleft = true;

                    // Alpha animation
                    tile.alpha -= dt * 15;
                    if (tile.alpha < 0) {
                        tile.alpha = 0;
                    }

                    if (tile.alpha == 0) {
                        tile.type = -1;
                        tile.alpha = 1;
                    }
                }
            }

            // Drop bubbles
            for (var i=0; i<this.floatingclusters.length; i++) {
                for (var j=0; j<this.floatingclusters[i].length; j++) {
                    var tile = this.floatingclusters[i][j];

                    if (tile.type >= 0) {
                        tilesleft = true;

                        // Accelerate dropped tiles
                        tile.velocity += dt * 700;
                        tile.shift += dt * tile.velocity;

                        // Alpha animation
                        tile.alpha -= dt * 8;
                        if (tile.alpha < 0) {
                            tile.alpha = 0;
                        }

                        // Check if the bubbles are past the bottom of the level
                        if (tile.alpha == 0 || (tile.y * this.level.rowheight + tile.shift > (this.level.rows - 1) * this.level.rowheight + this.level.tileheight)) {
                            tile.type = -1;
                            tile.shift = 0;
                            tile.alpha = 1;
                        }
                    }

                }
            }

            if (!tilesleft) {
                // Next bubble
                this.nextBubble();

                // Check for game over
                var tilefound = false
                for (var i=0; i<this.level.columns; i++) {
                    for (var j=0; j<this.level.rows; j++) {
                        if (this.level.tiles[i][j].type != -1) {
                            tilefound = true;
                            break;
                        }
                    }
                }

                if (tilefound) {
                    this.setGameState(this.gamestates.ready);
                } else {
                    // No tiles left, game over
                    this.setGameState(this.gamestates.gameover);
                }
            }
        }

        if(this.score != oldScore) {
            this.platform.sendScore(this.score);
        }
    }

    private snapBubble() {
        // Get the grid position
        var centerx = this.player.bubble.x + this.level.tilewidth/2;
        var centery = this.player.bubble.y + this.level.tileheight/2;
        var gridpos = this.getGridPosition(centerx, centery);

        // Make sure the grid position is valid
        if (gridpos.x < 0) {
            gridpos.x = 0;
        }

        if (gridpos.x >= this.level.columns) {
            gridpos.x = this.level.columns - 1;
        }

        if (gridpos.y < 0) {
            gridpos.y = 0;
        }

        if (gridpos.y >= this.level.rows) {
            gridpos.y = this.level.rows - 1;
        }

        // Check if the tile is empty
        var addtile = false;
        if (this.level.tiles[gridpos.x][gridpos.y].type != -1) {
            // Tile is not empty, shift the new tile downwards
            for (var newrow=gridpos.y+1; newrow<this.level.rows; newrow++) {
                if (this.level.tiles[gridpos.x][newrow].type == -1) {
                    gridpos.y = newrow;
                    addtile = true;
                    break;
                }
            }
        } else {
            addtile = true;
        }

        // Add the tile to the grid
        if (addtile) {
            // Hide the player bubble
            this.player.bubble.visible = false;

            // Set the tile
            this.level.tiles[gridpos.x][gridpos.y].type = this.player.bubble.tiletype;

            // Check for game over
            if (this.checkGameOver()) {
                return;
            }

            // Find clusters
            this.cluster = this.findCluster(gridpos.x, gridpos.y, true, true, false);

            if (this.cluster.length >= 3) {
                // Remove the cluster
                this.setGameState(this.gamestates.removecluster);
                return;
            }
        }

        // No clusters found
        this.turncounter++;
        if (this.config.turnsBeforeDrop && this.turncounter >= this.config.turnsBeforeDrop) {
            // Add a row of bubbles
            this.addBubbles();
            this.turncounter = 0;
            this.rowoffset = (this.rowoffset + 1) % 2;

            if (this.checkGameOver()) {
                return;
            }
        }

        // Next bubble
        this.nextBubble();
        this.setGameState(this.gamestates.ready);
    }

    private checkGameOver() {
        // Check for game over
        for (var i=0; i<this.level.columns; i++) {
            // Check if there are bubbles in the bottom row
            if (this.level.tiles[i][this.level.rows-1].type != -1) {
                // Game over
                this.nextBubble();
                this.setGameState(this.gamestates.gameover);
                return true;
            }
        }

        return false;
    }

    private addBubbles() {
        // Move the rows downwards
        for (var i=0; i<this.level.columns; i++) {
            for (var j=0; j<this.level.rows-1; j++) {
                this.level.tiles[i][this.level.rows-1-j].type = this.level.tiles[i][this.level.rows-1-j-1].type;
            }
        }

        // Add a new row of bubbles at the top
        for (var i=0; i<this.level.columns; i++) {
            // Add random, existing, colors
            this.level.tiles[i][0].type = this.getExistingColor();
        }
    }

    private findColors() {
        var foundcolors = [];
        var colortable = [];
        for (var i=0; i<this.bubblecolors; i++) {
            colortable.push(false);
        }

        // Check all tiles
        for (var i=0; i<this.level.columns; i++) {
            for (var j=0; j<this.level.rows; j++) {
                var tile = this.level.tiles[i][j];
                if (tile.type >= 0) {
                    if (!colortable[tile.type]) {
                        colortable[tile.type] = true;
                        foundcolors.push(tile.type);
                    }
                }
            }
        }

        return foundcolors;
    }

    private findCluster(tx, ty, matchtype, reset, skipremoved) {
        // Reset the processed flags
        if (reset) {
            this.resetProcessed();
        }

        // Get the target tile. Tile coord must be valid.
        var targettile = this.level.tiles[tx][ty];

        // Initialize the toprocess array with the specified tile
        var toprocess = [targettile];
        targettile.processed = true;
        var foundcluster = [];

        while (toprocess.length > 0) {
            // Pop the last element from the array
            var currenttile = toprocess.pop();

            // Skip processed and empty tiles
            if (currenttile.type == -1) {
                continue;
            }

            // Skip tiles with the removed flag
            if (skipremoved && currenttile.removed) {
                continue;
            }

            // Check if current tile has the right type, if matchtype is true
            if (!matchtype || (currenttile.type == targettile.type)) {
                // Add current tile to the cluster
                foundcluster.push(currenttile);

                // Get the neighbors of the current tile
                var neighbors = this.getNeighbors(currenttile);

                // Check the type of each neighbor
                for (var i=0; i<neighbors.length; i++) {
                    if (!neighbors[i].processed) {
                        // Add the neighbor to the toprocess array
                        toprocess.push(neighbors[i]);
                        neighbors[i].processed = true;
                    }
                }
            }
        }

        // Return the found cluster
        return foundcluster;
    }

    findFloatingClusters() {
        // Reset the processed flags
        this.resetProcessed();

        var foundclusters = [];

        // Check all tiles
        for (var i=0; i<this.level.columns; i++) {
            for (var j=0; j<this.level.rows; j++) {
                var tile = this.level.tiles[i][j];
                if (!tile.processed) {
                    // Find all attached tiles
                    var foundcluster = this.findCluster(i, j, false, false, true);

                    // There must be a tile in the cluster
                    if (foundcluster.length <= 0) {
                        continue;
                    }

                    // Check if the cluster is floating
                    var floating = true;
                    for (var k=0; k<foundcluster.length; k++) {
                        if (foundcluster[k].y == 0) {
                            // Tile is attached to the roof
                            floating = false;
                            break;
                        }
                    }

                    if (floating) {
                        // Found a floating cluster
                        foundclusters.push(foundcluster);
                    }
                }
            }
        }

        return foundclusters;
    }

    private resetProcessed() {
        for (var i=0; i<this.level.columns; i++) {
            for (var j=0; j<this.level.rows; j++) {
                this.level.tiles[i][j].processed = false;
            }
        }
    }

    private resetRemoved() {
        for (var i=0; i<this.level.columns; i++) {
            for (var j=0; j<this.level.rows; j++) {
                this.level.tiles[i][j].removed = false;
            }
        }
    }

    private getNeighbors(tile) {
        var tilerow = (tile.y + this.rowoffset) % 2; // Even or odd row
        var neighbors = [];

        // Get the neighbor offsets for the specified tile
        var n = this.neighborsoffsets[tilerow];

        // Get the neighbors
        for (var i=0; i<n.length; i++) {
            // Neighbor coordinate
            var nx = tile.x + n[i][0];
            var ny = tile.y + n[i][1];

            // Make sure the tile is valid
            if (nx >= 0 && nx < this.level.columns && ny >= 0 && ny < this.level.rows) {
                neighbors.push(this.level.tiles[nx][ny]);
            }
        }

        return neighbors;
    }

    private updateFps(dt) {
        if (this.fpstime > 0.25) {
            // Calculate fps
            this.fps = Math.round(this.framecount / this.fpstime);

            // Reset time and framecount
            this.fpstime = 0;
            this.framecount = 0;
        }

        // Increase time and framecount
        this.fpstime += dt;
        this.framecount++;
    }

    private drawCenterText(text, x, y, width) {
        var textdim = this.context.measureText(text);
        this.context.fillText(text, x + (width-textdim.width)/2, y);
    }

    private render() {
        var yoffset =  this.level.tileheight/2;

        // Render tiles
        this.renderTiles();

        // Draw level bottom
        this.context.fillStyle = this.config.bottomBarBackgroundColor;
        this.context.fillRect(this.level.x - 4, this.level.y - 4 + this.level.height + 4 - yoffset, this.level.width + 8, this.windowSize.height - (this.level.y - 4 + this.level.height + 4 - yoffset));

        // Draw score
        // this.context.fillStyle = "#ffffff";
        // this.context.font = "18px Verdana";
        // var scorex = this.level.x + this.level.width - 150;
        // var scorey = this.level.y+this.level.height + this.level.tileheight - yoffset - 8;
        // this.drawCenterText("Score:", scorex, scorey, 150);
        // this.context.font = "24px Verdana";
        // this.drawCenterText(this.score, scorex, scorey+30, 150);

        // Render cluster
        if (this.showcluster) {
            this.renderCluster(this.cluster, 255, 128, 128);

            for (var i=0; i<this.floatingclusters.length; i++) {
                var col = Math.floor(100 + 100 * i / this.floatingclusters.length);
                this.renderCluster(this.floatingclusters[i], col, col, col);
            }
        }

        // Render player bubble
        this.renderPlayer();

        // // Game Over overlay
        // if (this.gamestate == this.gamestates.gameover) {
        //     this.context.fillStyle = "rgba(0, 0, 0, 0.8)";
        //     this.context.fillRect(this.level.x - 4, this.level.y - 4, this.level.width + 8, this.level.height + 2 * this.level.tileheight + 8 - yoffset);
        //
        //     this.context.fillStyle = "#ffffff";
        //     this.context.font = "24px Verdana";
        //     this.drawCenterText("Game Over!", this.level.x, this.level.y + this.level.height / 2 + 10, this.level.width);
        //     this.drawCenterText("Click to start", this.level.x, this.level.y + this.level.height / 2 + 40, this.level.width);
        // }
    }

    private renderTiles() {
        // Top to bottom
        for (var j=0; j<this.level.rows; j++) {
            for (var i=0; i<this.level.columns; i++) {
                // Get the tile
                var tile = this.level.tiles[i][j];

                // Get the shift of the tile for animation
                var shift = tile.shift;

                // Calculate the tile coordinates
                var coord = this.getTileCoordinate(i, j);

                // Check if there is a tile present
                if (tile.type >= 0) {
                    // Support transparency
                    this.context.save();
                    this.context.globalAlpha = tile.alpha;

                    // Draw the tile using the color
                    this.drawBubble(coord.tilex, coord.tiley + shift, tile.type);

                    this.context.restore();
                }
            }
        }
    }

    private renderCluster(cluster, r, g, b) {
        for (var i=0; i<cluster.length; i++) {
            // Calculate the tile coordinates
            var coord = this.getTileCoordinate(cluster[i].x, cluster[i].y);

            // Draw the tile using the color
            this.context.fillStyle = "rgb(" + r + "," + g + "," + b + ")";
            this.context.fillRect(coord.tilex+this.level.tilewidth/4, coord.tiley+this.level.tileheight/4, this.level.tilewidth/2, this.level.tileheight/2);
        }
    }

    private renderPlayer() {
        var centerx = this.player.x + this.level.tilewidth/2;
        var centery = this.player.y + this.level.tileheight/2;

        // Draw player background circle
        this.context.beginPath();
        this.context.arc(centerx, centery, this.level.radius+12, 0, 2*Math.PI, false);
        this.context.fill();
        this.context.lineWidth = 2;
        this.context.stroke();

        // Draw the angle
        this.context.lineWidth = 2;
        this.context.strokeStyle = "#0000ff";
        this.context.beginPath();
        this.context.moveTo(centerx, centery);
        this.context.lineTo(centerx + 1.5*this.level.tilewidth * Math.cos(this.degToRad(this.player.angle)), centery - 1.5*this.level.tileheight * Math.sin(this.degToRad(this.player.angle)));
        this.context.stroke();

        // Draw the next bubble
        this.drawBubble(this.player.nextbubble.x, this.player.nextbubble.y, this.player.nextbubble.tiletype);

        // Draw the bubble
        if (this.player.bubble.visible) {
            this.drawBubble(this.player.bubble.x, this.player.bubble.y, this.player.bubble.tiletype);
        }
    }

    private getTileCoordinate(column, row) {
        var tilex = this.level.x + column * this.level.tilewidth;

        // X offset for odd or even rows
        if ((row + this.rowoffset) % 2) {
            tilex += this.level.tilewidth/2;
        }

        var tiley = this.level.y + row * this.level.rowheight;
        return { tilex: tilex, tiley: tiley };
    }

    private getGridPosition(x, y) {
        var gridy = Math.floor((y - this.level.y) / this.level.rowheight);

        // Check for offset
        var xoffset = 0;
        if ((gridy + this.rowoffset) % 2) {
            xoffset = this.level.tilewidth / 2;
        }
        var gridx = Math.floor(((x - xoffset) - this.level.x) / this.level.tilewidth);

        return { x: gridx, y: gridy };
    }

    drawBubble(x, y, index) {
        if (index < 0 || index >= this.bubblecolors)
            return;

        // Draw the bubble sprite
        this.context.drawImage(
            this.bubbleimage,
            index * this.bubbleimage.height,
            0,
            this.bubbleimage.height,
            this.bubbleimage.height,
            x,
            y,
            this.level.tilewidth,
            this.level.tileheight
        );
    }

    private createLevel() {
        // Create a level with random tiles
        for (var j=0; j<this.level.rows; j++) {
            var randomtile = this.getExistingColor();
            var count = 0;
            for (var i=0; i<this.level.columns; i++) {
                if (count >= 2) {
                    // Change the random tile
                    var newtile = this.getExistingColor();

                    // Make sure the new tile is different from the previous tile
                    if (newtile == randomtile) {
                        newtile = (newtile + 1) % this.bubblecolors;
                    }
                    randomtile = newtile;
                    count = 0;
                }
                count++;

                if (j < this.level.rows/2) {
                    this.level.tiles[i][j].type = randomtile;
                } else {
                    this.level.tiles[i][j].type = -1;
                }
            }
        }
    }

    private nextBubble() {
        // Set the current bubble
        this.player.tiletype = this.player.nextbubble.tiletype;
        this.player.bubble.tiletype =this.player.nextbubble.tiletype;
        this.player.bubble.x = this.player.x;
        this.player.bubble.y = this.player.y;
        this.player.bubble.visible = true;

        // Get a random type from the existing colors
        var nextcolor = this.getExistingColor();

        // Set the next bubble
        this.player.nextbubble.tiletype = nextcolor;
    }

    private getExistingColor() {
        this.existingcolors = this.findColors();

        var bubbletype = 0;
        if (this.existingcolors.length > 0) {
            bubbletype = this.generateBubbleType();
        }

        return bubbletype;
    }

    private generateBubbleType() {
        let ballSettings = this.config.ballSettings || [];

        if(!ballSettings) {
            return this.existingcolors[this.randRange(0, this.existingcolors.length-1)];
        }

        for(let i = ballSettings.length; i < this.config.numberOfBallsOnSprite; i++) {
            ballSettings.push({chance: 1, points: 100} as ballSettings);
        }

        const totalWeight = ballSettings.reduce((acc, setting) => acc + setting.chance, 0);
        let random = Math.random() * totalWeight;

        for (let i = 0; i < ballSettings.length; i++) {
            if (random < ballSettings[i].chance) {
                return i;  // return the index of the selected bubble type
            }
            random -= ballSettings[i].chance;
        }

        return 0;  // Default return, should not reach here if weights are set up correctly
    }

    private randRange(low, high) {
        return Math.floor(low + Math.random()*(high-low+1));
    }

    private shootBubble() {
        // Shoot the bubble in the direction of the mouse
        this.player.bubble.x = this.player.x;
        this.player.bubble.y = this.player.y;
        this.player.bubble.angle = this.player.angle;
        this.player.bubble.tiletype = this.player.tiletype;

        // Set the gamestate
        this.setGameState(this.gamestates.shootbubble);
    }

    private circleIntersection(x1, y1, r1, x2, y2, r2) {
        // Calculate the distance between the centers
        var dx = x1 - x2;
        var dy = y1 - y2;
        var len = Math.sqrt(dx * dx + dy * dy);

        if (len < r1 + r2) {
            // Circles intersect
            return true;
        }

        return false;
    }

    private radToDeg(angle) {
        return angle * (180 / Math.PI);
    }

    // Convert degrees to radians
    private degToRad(angle) {
        return angle * (Math.PI / 180);
    }

    private onMouseMove = (e) => {
        // Get the mouse position
        var pos = this.getMousePos(this.canvas, e);

        // Get the mouse angle
        var mouseangle = this.radToDeg(Math.atan2((this.player.y+this.level.tileheight/2) - pos.y, pos.x - (this.player.x+this.level.tilewidth/2)));

        // Convert range to 0, 360 degrees
        if (mouseangle < 0) {
            mouseangle = 180 + (180 + mouseangle);
        }

        // Restrict angle to 8, 172 degrees
        var lbound = 8;
        var ubound = 172;
        if (mouseangle > 90 && mouseangle < 270) {
            // Left
            if (mouseangle > ubound) {
                mouseangle = ubound;
            }
        } else {
            // Right
            if (mouseangle < lbound || mouseangle >= 270) {
                mouseangle = lbound;
            }
        }

        // Set the player angle
        this.player.angle = mouseangle;
    }

    private onMouseDown = (e)=> {
        // Get the mouse position
        var pos = this.getMousePos(this.canvas, e);

        if (this.gamestate == this.gamestates.ready) {
            this.shootBubble();
        } else if (this.gamestate == this.gamestates.gameover) {
            // this.newGame();
        }
    }

    private getMousePos(canvas, e) {
        var rect = canvas.getBoundingClientRect();
        return {
            x: Math.round((e.clientX - rect.left)/(rect.right - rect.left)*canvas.width),
            y: Math.round((e.clientY - rect.top)/(rect.bottom - rect.top)*canvas.height)
        };
    }

    private newGame() {
        console.log('Restarting game...');

        // Reset score
        this.score = 0;

        this.turncounter = 0;
        this.rowoffset = 0;

        // Create the level
        this.createLevel();

        // Set the gamestate to ready
        this.setGameState(this.gamestates.ready);

        // Init the next bubble and set the current bubble
        this.nextBubble();
        this.nextBubble();

        this.checkForTimedDrop();

        (document.getElementById('game-screen') as HTMLElement).style.opacity = '1';
    }

    private loadImages(imagefiles) {
        // Initialize variables
        this.loadcount = 0;
        this.loadtotal = imagefiles.length;
        this.preloaded = false;

        // Load the images
        var loadedimages = [];
        for (var i=0; i<imagefiles.length; i++) {
            // Create the image object
            var image = new Image();

            // Add onload event handler
            image.onload = () => {
                this.loadcount++;
                if (this.loadcount == this.loadtotal) {
                    // Done loading
                    this.preloaded = true;
                }
            };

            // Set the source url of the image
            image.src = imagefiles[i];

            // Save to the image array
            loadedimages[i] = image;
        }

        console.log('Loaded images:', loadedimages);
        // Return an array of images
        return loadedimages;
    }


    private findScoreForTile(tile: Tile) {
        let ballSettings = this.config.ballSettings || [];
        for(let i = ballSettings.length; i < this.config.numberOfBallsOnSprite; i++) {
            ballSettings.push({chance: 1, points: 100} as ballSettings);
        }

        return ballSettings[tile.type].points;
    }

    public play() {
        this.newGame();
    };
    public restart() {
        this.newGame();
    }
}
