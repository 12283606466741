import { Configurator } from "./Configurator";
import {GameConfig, GameConfigInterface} from "./GameConfig";

const customConfig = getSettingsFromURI(location.search) as typeof GameConfig;
let config = new GameConfig();

if (customConfig)
  for (const [key, value] of Object.entries(customConfig)) {
      if(key === 'ballSettings') {
          const ballSettings = [];
          value.split(',').forEach((value) => {
              const chance = value.split(':')[0] ?? 1;
              const score = value.split(':')[1] ?? 100;
              ballSettings.push({
                  chance: parseInt(chance),
                  points: parseInt(score),
              });
          });

          (config as any)['ballSettings'] = ballSettings;
      } else if(['turnsBeforeDrop','secondsBeforeDrop','speedOfShot','numberOfBallsOnSprite','ballsPerRow'].includes(key)) {
          (config as any)[key] = parseInt(value);
      } else {
          (config as any)[key] = value;
      }
  }

console.log(config);
const configurator = new Configurator();
configurator.init(config);

function getSettingsFromURI(uri: string) {
  let settingString;
  const split = uri.split("&");
  for (let s of split) {
    if (s[0] === "s") {
      settingString = decodeURIComponent(escape(atob(s.split("=")[1])));
    }
  }

  if (settingString === undefined) {
    return null;
  }

  let jsonString = "{";
  let settings: string[] = settingString.split("\n");

  settings.forEach((s) => {
    const keyAndValue = s.split("=");
    if (keyAndValue.length === 1) {
      return;
    }

    const key = keyAndValue[0].trim();
    const value = keyAndValue[1].trim();

    if (value === "default") {
      return;
    }

    let line = '"' + key.toString() + '": ';

    if (value.includes("[") && value.includes("]")) {
      line += value.toString();
    } else {
      line += '"' + value.toString() + '"';
    }

    if (settings.indexOf(s) < settings.length - 1) {
      line += ",";
    }

    jsonString += line;
  });

  jsonString += "}";

  return JSON.parse(jsonString);
}
